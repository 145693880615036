import React from "react";

import { Text, Center, Box, Image, Heading, Button } from "native-base";

import {
  ActivityIndicator, Linking,
} from 'react-native';

import AuthContext from "../src/AuthContext";


const ThankyouView = ({ navigation }) => {


  const { signOut } = React.useContext(AuthContext);

  return (
    <Center w="100vw" h="100vh" style={{ shadowOpacity: 0 }}>
      <Box safeArea p="2" py="8" w="90%" maxW="330">
        <Image alt='YellowCat Store Logo' size="xl" resizeMode="contain" alignSelf={"center"} source={{
          uri: "https://store1.yellowcat.dev/cdn/shop/files/YCS-800.png?v=1686000958&width=1300"
        }} />
        <Heading size="xl" fontWeight="600" color="coolGray.800" textAlign={"center"}>
          Vielen Dank für Dein Feedback
        </Heading>

        <Text mt="2" color="coolGray.600" fontWeight="medium" textAlign={"center"}>
          Wir werden Dir innerhalb der nächsten <Text bold>48 Stunden</Text> Deinen <Text bold>15% Rabatt-Gutscheincode</Text> per E-Mail zusenden.
        </Text>

        <Button onPress={() => {
          Linking.openURL("https://yellowcatstore.com")
        }} mt="2" backgroundColor="brand.900" _text={{
          color: "black",
          fontSize: "md",
          fontWeight: "bold"
        }}>
          ZUM STORE
        </Button>
        <Button onPress={() => {
          signOut()
        }} mt="1" backgroundColor="gray.100" _text={{
          color: "black",
          fontSize: "md",
          fontWeight: "bold"
        }}>
          WEITERE BESTELLUNG BEWERTEN
        </Button>
      </Box>
    </Center>
  );
};


export default ThankyouView;