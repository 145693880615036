import React from "react";

import { Text, Center, Box, Image, Heading, Spinner } from "native-base";

import {
  ActivityIndicator,
} from 'react-native';


const SplashScreenView = () => {
  return (
    <Center w="100vw" h="100vh" style={{ shadowOpacity: 0 }}>
      <Box safeArea p="2" py="8" w="90%" maxW="330">
        <Image alt='YellowCat Store Logo' size="xl" resizeMode="contain" alignSelf={"center"} source={{
          uri: "https://store1.yellowcat.dev/cdn/shop/files/YCS-800.png?v=1686000958&width=1300"
        }} />
        <Heading mb="3" size="xl" fontWeight="600" color="coolGray.800" textAlign={"center"}>
          Wird geladen...
        </Heading>

        <Spinner size="lg" color="brand.900" />
      </Box>
    </Center>
  );
};


export default SplashScreenView;