import { serverApiUrl } from "./ApiConnection";

const fetchApiServerPostWithoutAuth = async (query,body) => {
  console.log("fetchApiServerPostWithoutAuth", query, body)
  try {
    const response = await fetch(
      serverApiUrl + query, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
  }
};

const fetchApiServerGetWithoutAuth = async (query,body) => {
  console.log("fetchApiServerGetWithoutAuth", query, body)
  try {
    const response = await fetch(
      serverApiUrl + query, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
  }
};

const saveFeedback = async (orderData, products, freetext, found) => {
  console.log("saveFeedback()");
  console.log(orderData);
  console.log(products);
  try {
    const r = await fetchApiServerPostWithoutAuth("feedback/saveFeedback", {
      orderName: orderData.name,
      customerEmail: orderData.customer.email,
      orderId: orderData.id,
      products: products,
      freetext: freetext,
      found: found
    });
    console.log(r);
    return r
  } catch (error) {
    console.log("getOrder:fetchApiServerPost:catch:error");
    console.log(error);
    return false
  }
}

const getOrder = async (orderName, customerEmail) => {

  if(orderName.length < 3 && customerEmail.length < 3) {
    return false
  }

  try {
    const r = await fetchApiServerPostWithoutAuth("feedback/getOrder", {
      orderName:orderName,
      customerEmail:customerEmail
    });
    console.log(r);
    return r
  } catch (error) {
    console.log("getOrder:fetchApiServerPost:catch:error");
    console.log(error);
    return false
  }
}


export { 
  saveFeedback,
  getOrder,
};