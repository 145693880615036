import { StatusBar } from 'expo-status-bar';

import React from "react";
import { NativeBaseProvider, Text, Box, extendTheme, Center, Heading, VStack, FormControl, Input, InputGroup, HStack, Link, Button, Image, InfoOutlineIcon } from "native-base";

import NavigatorManager from './src/NavigatorManager';

// 2. Extend the theme to include custom colors, fonts, etc
const newColorTheme = {
  brand: {
    900: "#FFD311",
  },
  primary: {
    50: '#E3F2F9',
    100: '#C5E4F3',
    200: '#A2D4EC',
    300: '#7AC1E4',
    400: '#47A9DA',
    500: '#0088CC',
    600: '#007AB8',
    700: '#006BA1',
    800: '#005885',
    900: '#003F5E',
  },
};

const newComponentTheme = {
  components: {
    Input: {
      _focus: {
        border: 0,
        boxShadow: "none",
      },
      _input: {
        border: 0,
        boxShadow: "none",
      },
      baseStyle: {
        border: 0,
        boxShadow: "none",
      },
    }
  }
};



const theme = extendTheme({ colors: newColorTheme, components: newComponentTheme });





export default function App() {

  


  return (
      <NativeBaseProvider theme={theme}>
        <NavigatorManager />
      </NativeBaseProvider>
  );
}