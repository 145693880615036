import React, { useState, useEffect, useRef, useContext } from "react";

import { Text, Center, Box, Image, Heading, Button, HStack, VStack, FormControl, Input, Alert, Pressable, CheckIcon, Select, Collapse, IconButton, CloseIcon, Link } from "native-base";

import StarRating from 'react-native-star-rating-widget';

import {
  ActivityIndicator, Linking,
} from 'react-native';

import AuthContext from "../src/AuthContext";
import { saveFeedback } from "../src/ServerFunctions";


const RatingView = ({ navigation, route }) => {

  useContext
  const { signIn } = useContext(AuthContext);

  const [rating, setRating] = useState(0);
  const [found, setFound] = useState(0);
  const [freetext, setFreetext] = useState("");
  const inputFreetextRef = useRef();

  const [products, setProducts] = useState([]);



  const { orderData } = route.params;


  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const onClose = () => setShowAlert(false);
  const cancelRef = useRef(null);


  async function submitRating() {

    setIsLoading(true)
    setShowAlert(false)

    console.log(inputFreetextRef.current.value);

    const feedback = await saveFeedback(orderData, products, inputFreetextRef.current.value, found)

    if (feedback && feedback.status == 200 && feedback.response) {
      console.log("OK");
      navigation.navigate("ThankyouView")
    } else {
      console.log("NO")
      setShowAlert(true)
      setIsLoading(false)
    }

  }

  useEffect(() => {

    setProducts(route.params?.orderData.products)

  }, []);


  const SetStarValue = (star, index, value) => {
    let p = [...products];

    switch (star) {
      case 0:
        p[index] = { ...p[index], ratingSatisfied: value };
        break;

      case 1:
        p[index] = { ...p[index], ratingDesign: value };
        break;

      case 2:
        p[index] = { ...p[index], ratingFit: value };
        break;

      case 3:
        p[index] = { ...p[index], ratingQuality: value };
        break;

      default:
        break;
    }

    setProducts(p);
  }



  const ProductItem = (element, index) => {
    // console.log(element);
    // console.log(index);
    return (
      <HStack space={1} key={index}>
        <Image mr={3} alt='Produktbild' size="2xl" resizeMode="contain" alignSelf={"center"} source={{
          uri: element.image
        }} />
        <VStack>
          <VStack>
            <Heading size={"md"} mt="2" color="coolGray.600" fontWeight="bold">
              {element.name}
            </Heading>
            {/* <Text mt="1" color="coolGray.600">
              <Link href="https://yellowcatstore.com/" isExternal>Produkt im Store ansehen</Link>
            </Text> */}
          </VStack>

          <HStack space={4}>

            <VStack>
              <VStack>
                <Text mt="2" mb="1" color="coolGray.600" fontWeight="bold">
                  Wie zufrieden bist Du?
                </Text>
                <StarRating
                  rating={element.ratingSatisfied}
                  onChange={(rating) => {
                    console.log(rating);
                    SetStarValue(0, index, rating)
                  }}
                />
              </VStack>

              <VStack>
                <Text mt="2" mb="1" color="coolGray.600" fontWeight="bold">
                  Wie gefällt Dir das Design?
                </Text>
                <StarRating
                  rating={element.ratingDesign}
                  onChange={(rating) => {
                    SetStarValue(1, index, rating)
                  }}
                />
              </VStack>

            </VStack>

            <VStack>
              <VStack>
                <Text mt="2" mb="1" color="coolGray.600" fontWeight="bold">
                  Wie bewertest Du die Passform?
                </Text>
                <StarRating
                  rating={element.ratingFit}
                  onChange={(rating) => {
                    SetStarValue(2, index, rating)
                  }}
                />
              </VStack>

              <VStack>
                <Text mt="2" mb="1" color="coolGray.600" fontWeight="bold">
                  Wie bewertest Du die Produktqualität?
                </Text>
                <StarRating
                  rating={element.ratingQuality}
                  onChange={(rating) => {
                    SetStarValue(3, index, rating)
                  }}
                />
              </VStack>

            </VStack>
          </HStack>
        </VStack>
      </HStack>
    )
  };

  // const ProductList = () => {
  //   for (let index = 0; index < orderData.products.length; index++) {
  //     const element = orderData.products[index];

  //     return (
  //       ProductItem(element)
  //     )
  //   }
  // };

  return (
    <Center w="100vw">
      <Box safeArea py="2" w="90%">
        <HStack justifyContent={"flex-start"} alignItems={"center"} space={3} mb={5}>
          <Pressable onPress={() => {
            Linking.openURL("https://yellowcatstore.com/")
          }}>
            <Image alt='YellowCat Store Logo' size="xl" resizeMode="contain" justifyContent={"center"} alignSelf={"center"} source={{
              uri: "https://store1.yellowcat.dev/cdn/shop/files/YCS-800.png?v=1686000958&width=1300"
            }} />
          </Pressable>
          <VStack space={1}>
            <Heading size="xl" fontWeight="600" color="coolGray.800">
              Dein Feedback
            </Heading>
            <Heading size="md" fontWeight="600" color="coolGray.800">
              Schön, dass Du da bist, {orderData.customer.first_name}!
            </Heading>
            <VStack space={1}>
              <Text fontWeight="normal" color="gray.400">Bestellnummer: {orderData.name}</Text>
              <Text fontWeight="normal" color="gray.400">Datum: {new Date(orderData.created_at).toLocaleDateString()}</Text>
            </VStack>
          </VStack>
        </HStack>

        {orderData.customer.email_marketing_consent.state != "subscribed" ? (
          <Pressable mb={5} onPress={() => {
            Linking.openURL("https://yellowcatstore.com/pages/newsletter")
          }}>
            <Alert status="info" colorScheme="info">
              <VStack space={2} flexShrink={1} w="100%">
                <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
                  <HStack flexShrink={1} space={2} alignItems="center">
                    <Alert.Icon />
                    <Text fontSize="md" fontWeight="medium" color="coolGray.800">
                      Für Newsletter eintragen
                    </Text>
                  </HStack>
                </HStack>
                <Box pl="6" _text={{
                  color: "coolGray.600"
                }}>
                  Verpasse nicht mehr unsere aktuellen Angeboten und trage Dich jetzt zu unserem Newsletter ein.
                  <Text mt="2" fontSize={12} fontWeight="medium" textDecorationLine="underline" color="darkBlue.600" alignSelf="flex-start">
                    Hier eintragen
                  </Text>
                </Box>
              </VStack>
            </Alert>
          </Pressable>
        ) : null}


        <Collapse isOpen={showAlert}>
          <Alert mb="5" status="warning">
            <VStack space={1} flexShrink={1} w="100%">
              <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
                <HStack flexShrink={1} space={2} alignItems="center">
                  <Alert.Icon />
                  <Text fontSize="md" fontWeight="medium" _dark={{
                    color: "coolGray.800"
                  }}>
                    Feedbackabgabe nicht möglich
                  </Text>
                </HStack>
                <IconButton variant="unstyled" _focus={{
                  borderWidth: 0
                }} icon={<CloseIcon size="3" />} _icon={{
                  color: "coolGray.600"
                }} onPress={() => setShowAlert(false)} />
              </HStack>
              <Box pl="6" _dark={{
                _text: {
                  color: "coolGray.600"
                }
              }}>
                Dein Feedback konnte nicht gespeichert werden. Prüfe, ob Du die Bestellung bereits bewertet hast. Wenn Du Fragen hast oder Hilfe benötigst <Link href="https://yellowcatstore.com/pages/kontakt" isExternal>kontaktiere uns</Link> gerne.
              </Box>
            </VStack>
          </Alert>
        </Collapse>

        <VStack space={8}>

          {products.map((index, value) => {
            return ProductItem(index, value)
          })}

          {/* <HStack>
            <Image alt='Produktbild' size="2xl" resizeMode="contain" alignSelf={"center"} source={{
              uri: "https://store1.yellowcat.dev/cdn/shop/files/unisex-organic-cotton-t-shirt-anthracite-front-647f80e532a22.jpg?v=1686077682&width=1646"
            }} />
            <VStack>
              <VStack>
                <Heading size={"md"} mt="2" color="coolGray.600" fontWeight="bold">
                  Unisex-Bio-Baumwoll-T-Shirt
                </Heading>
                <Text mt="1" color="coolGray.600">
                  <Link href="https://yellowcat.dev" isExternal>Produkt im Store ansehen</Link>
                </Text>
              </VStack>

              <HStack space={3}>

                <VStack>
                  <VStack>
                    <Text mt="2" color="coolGray.600" fontWeight="bold">
                      Wie zufrieden bist Du?
                    </Text>
                    <Text mt="1" color="coolGray.600">
                      O O O O O
                    </Text>
                  </VStack>

                  <VStack>
                    <Text mt="2" color="coolGray.600" fontWeight="bold">
                      Wie gefällt Dir das Design?
                    </Text>
                    <Text mt="1" color="coolGray.600">
                      O O O O O
                    </Text>
                  </VStack>

                </VStack>

                <VStack>
                  <VStack>
                    <Text mt="2" color="coolGray.600" fontWeight="bold">
                      Wie bewertest Du die Passform?
                    </Text>
                    <Text mt="1" color="coolGray.600">
                      O O O O O
                    </Text>
                  </VStack>

                  <VStack>
                    <Text mt="2" color="coolGray.600" fontWeight="bold">
                      Wie gefällt Dir das Design?
                    </Text>
                    <Text mt="1" color="coolGray.600">
                      O O O O O
                    </Text>
                  </VStack>

                </VStack>
              </HStack>
            </VStack>
          </HStack>

          <HStack>
            <Image alt='Produktbild' size="2xl" resizeMode="contain" alignSelf={"center"} source={{
              uri: "https://store1.yellowcat.dev/cdn/shop/files/unisex-organic-cotton-t-shirt-anthracite-front-647f80e532a22.jpg?v=1686077682&width=1646"
            }} />
            <VStack>
              <VStack>
                <Heading size={"md"} mt="2" color="coolGray.600" fontWeight="bold">
                  Unisex-Bio-Baumwoll-T-Shirt
                </Heading>
                <Text mt="1" color="coolGray.600">
                  <Link href="https://yellowcat.dev" isExternal>Produkt im Store ansehen</Link>
                </Text>
              </VStack>

              <HStack space={3}>

                <VStack>
                  <VStack>
                    <Text mt="2" color="coolGray.600" fontWeight="bold">
                      Wie zufrieden bist Du?
                    </Text>
                    <Text mt="1" color="coolGray.600">
                      O O O O O
                    </Text>
                  </VStack>

                  <VStack>
                    <Text mt="2" color="coolGray.600" fontWeight="bold">
                      Wie gefällt Dir das Design?
                    </Text>
                    <Text mt="1" color="coolGray.600">
                      O O O O O
                    </Text>
                  </VStack>

                </VStack>

                <VStack>
                  <VStack>
                    <Text mt="2" color="coolGray.600" fontWeight="bold">
                      Wie bewertest Du die Passform?
                    </Text>
                    <Text mt="1" color="coolGray.600">
                      O O O O O
                    </Text>
                  </VStack>

                  <VStack>
                    <Text mt="2" color="coolGray.600" fontWeight="bold">
                      Wie gefällt Dir das Design?
                    </Text>
                    <Text mt="1" color="coolGray.600">
                      O O O O O
                    </Text>
                  </VStack>

                </VStack>
              </HStack>
            </VStack>
          </HStack>

          <HStack>
            <Image alt='Produktbild' size="2xl" resizeMode="contain" alignSelf={"center"} source={{
              uri: "https://store1.yellowcat.dev/cdn/shop/files/unisex-organic-cotton-t-shirt-anthracite-front-647f80e532a22.jpg?v=1686077682&width=1646"
            }} />
            <VStack>
              <VStack>
                <Heading size={"md"} mt="2" color="coolGray.600" fontWeight="bold">
                  Unisex-Bio-Baumwoll-T-Shirt
                </Heading>
                <Text mt="1" color="coolGray.600">
                  <Link href="https://yellowcat.dev" isExternal>Produkt im Store ansehen</Link>
                </Text>
              </VStack>

              <HStack space={3}>

                <VStack>
                  <VStack>
                    <Text mt="2" color="coolGray.600" fontWeight="bold">
                      Wie zufrieden bist Du?
                    </Text>
                    <Text mt="1" color="coolGray.600">
                      O O O O O
                    </Text>
                  </VStack>

                  <VStack>
                    <Text mt="2" color="coolGray.600" fontWeight="bold">
                      Wie gefällt Dir das Design?
                    </Text>
                    <Text mt="1" color="coolGray.600">
                      O O O O O
                    </Text>
                  </VStack>

                </VStack>

                <VStack>
                  <VStack>
                    <Text mt="2" color="coolGray.600" fontWeight="bold">
                      Wie bewertest Du die Passform?
                    </Text>
                    <Text mt="1" color="coolGray.600">
                      O O O O O
                    </Text>
                  </VStack>

                  <VStack>
                    <Text mt="2" color="coolGray.600" fontWeight="bold">
                      Wie bewertest Du die Produktqualität?
                    </Text>
                    <Text mt="1" color="coolGray.600">
                      O O O O O
                    </Text>
                  </VStack>

                </VStack>
              </HStack>
            </VStack>
          </HStack> */}
        </VStack>
        <VStack space={3} mt="5">
          <FormControl>
            <FormControl.Label>Deine Meinung</FormControl.Label>
            <FormControl.HelperText>
              Teile uns gerne weiteres Feedback mit. Uns interessiert sehr Deine Meinung zu Deinem vollständigen Einkaufserlebnis - von Deiner Produktauswahl in unserem Store bis zum gelieferten Produkt in Deinen Händen.
            </FormControl.HelperText>
            <Input ref={inputFreetextRef} 
            // onChangeText={(text) => {
            //   setFreetext(text)
            //   console.log("onChangeText");
            //   console.log(text);
            //   // inputFreetextRef.text = text 
            // }} 
            // value={freetext}
            // onChangeText={text => inputFreetextRef.text = text }
            isDisabled={isLoading} multiline type='text' height={"20"} borderColor={"brand.900"} _hover={{ style: { borderColor: "brand.900", shadow: "none" } }} _focus={{
              borderColor: "brand.900",
            }} _input={{ shadow: "none", borderColor: "brand.900" }} placeholder='' />

          </FormControl>

          <FormControl>
            <FormControl.Label>Wie hast Du von uns erfahren?</FormControl.Label>
            <Select selectedValue={found} minWidth="200" accessibilityLabel="Wähle aus..." placeholder="Wähle aus..." _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size="5" />
            }} mt={1} onValueChange={itemValue => setFound(itemValue)}>
              <Select.Item label="Online-Werbung" value="1" />
              <Select.Item label="Suchmaschinen" value="2" />
              <Select.Item label="Empfehlung von Freunden oder Familie" value="3" />
              <Select.Item label="Soziale Medien" value="4" />
              <Select.Item label="Sonstiges" value="0" />
            </Select>
          </FormControl>

          <Collapse isOpen={showAlert}>
            <Alert mt="5" status="warning">
              <VStack space={1} flexShrink={1} w="100%">
                <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
                  <HStack flexShrink={1} space={2} alignItems="center">
                    <Alert.Icon />
                    <Text fontSize="md" fontWeight="medium" _dark={{
                      color: "coolGray.800"
                    }}>
                      Feedbackabgabe nicht möglich
                    </Text>
                  </HStack>
                  <IconButton variant="unstyled" _focus={{
                    borderWidth: 0
                  }} icon={<CloseIcon size="3" />} _icon={{
                    color: "coolGray.600"
                  }} onPress={() => setShowAlert(false)} />
                </HStack>
                <Box pl="6" _dark={{
                  _text: {
                    color: "coolGray.600"
                  }
                }}>
                  Dein Feedback konnte nicht gespeichert werden. Prüfe, ob Du die Bestellung bereits bewertet hast.  Wenn Du Fragen hast oder Hilfe benötigst <Link href="https://yellowcatstore.com/pages/kontakt" isExternal>kontaktiere uns</Link> gerne.
                </Box>
              </VStack>
            </Alert>
          </Collapse>

          <Button isLoading={isLoading} onPress={() => {
            submitRating()
          }} mt="2" backgroundColor="brand.900" _text={{
            color: "black",
            fontSize: "md",
            fontWeight: "bold"
          }}>

            FEEDBACK ABGEBEN
          </Button>
          <Button mt="1" backgroundColor="gray.100" _text={{
            color: "black",
            fontSize: "md",
            fontWeight: "bold"
          }}>
            KONTAKTIERE UNS
          </Button>
        </VStack>
      </Box>
    </Center>
  );
};


export default RatingView;