import React from "react";

import { Text, Center, Box, Image, Heading, VStack, FormControl, Input, Link, InfoOutlineIcon, Button, Alert, IconButton, Collapse, HStack, CloseIcon, Pressable } from "native-base";

import {
  ActivityIndicator, Linking,
} from 'react-native';

import AuthContext from "../src/AuthContext";
import { getOrder } from "../src/ServerFunctions";



const LoginView = ({ navigation, route }) => {


  const { signIn } = React.useContext(AuthContext);

  const [isLoading, setIsLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [customerEmail, setCustomerEmail] = React.useState('');
  const [orderName, setOrderName] = React.useState('');

  const { setOrderData } = route.params;

  async function loginWithOrder() {

    setIsLoading(true)
    setShowAlert(false)

    const order = await getOrder(orderName, customerEmail)

    if (order && order.status == 200 && order.response.confirmed == true) {
      console.log("OK");
      setOrderData(order.response)
      // setLoginStatus(200)
      signIn({ customerEmail: customerEmail, orderName: orderName })
    } else {
      console.log("NO")
      setShowAlert(true)
      setIsLoading(false)
    }

    //signIn({ username: "username", password: "password" })

  }

  return (
    <Center w="100vw" h="100vh" style={{ shadowOpacity: 0 }}>
      <Box safeArea p="2" py="8" w="90%" maxW="330">
        <Pressable onPress={() => {
          Linking.openURL("https://yellowcatstore.com")
        }}>
          <Image alt='YellowCat Store Logo' size="xl" resizeMode="contain" alignSelf={"center"} source={{
            uri: "https://store1.yellowcat.dev/cdn/shop/files/YCS-800.png?v=1686000958&width=1300"
          }} />
        </Pressable>
        <Heading size="xl" fontWeight="600" color="coolGray.800" textAlign={"center"}>
          Dein Feedback
        </Heading>

        <Text mt="2" color="coolGray.600" fontWeight="medium" textAlign={"center"}>
          Sag uns Deine Meinung und erhalte <Text bold>15% Rabatt </Text>auf Deine nächste Bestellung.
        </Text>
        <Text mt="1" color="coolGray.600" fontWeight="medium" textAlign={"center"}>
          Wenn Du Fragen hast oder Hilfe benötigst <Link href="https://yellowcatstore.com/pages/kontakt" isExternal>kontaktiere uns</Link> gerne direkt.
        </Text>

        <Collapse isOpen={showAlert}>
          <Alert mt="5" maxW="400" status="warning">
            <VStack space={1} flexShrink={1} w="100%">
              <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
                <HStack flexShrink={1} space={2} alignItems="center">
                  <Alert.Icon />
                  <Text fontSize="md" fontWeight="medium" _dark={{
                    color: "coolGray.800"
                  }}>
                    Bestellung nicht gefunden
                  </Text>
                </HStack>
                <IconButton variant="unstyled" _focus={{
                  borderWidth: 0
                }} icon={<CloseIcon size="3" />} _icon={{
                  color: "coolGray.600"
                }} onPress={() => setShowAlert(false)} />
              </HStack>
              <Box pl="6" _dark={{
                _text: {
                  color: "coolGray.600"
                }
              }}>
                Wir haben Deine Bestellung nicht gefunden. Prüfe bitte Deine Eingaben. Du kannst nur Feedback für Bestellungen abgeben, die Du bereits erhalten und noch nicht bewertet hast.
              </Box>
            </VStack>
          </Alert>
        </Collapse>

        <VStack space={3} mt="5" textAlign={"center"}>
          <FormControl>
            <FormControl.Label justifyContent={"center"}>Bestellnummer</FormControl.Label>
            <Input onChangeText={(text) => {
              setOrderName(text)
            }} isDisabled={isLoading} style={{ appearance: "none", shadowOffset: { height: 0, width: 0 } }} shadow="none" borderColor={"brand.900"} _hover={{ style: { borderColor: "brand.900", shadow: "none" } }} _focus={{
              backgroundColor: "yellow.200",
              borderColor: "yellow.200",
            }} _input={{ shadow: "none", borderColor: "brand.900" }} textAlign={"center"} placeholder='YCS-01234' />
            <FormControl.HelperText leftIcon={<InfoOutlineIcon size="xs" />}>
              Die Bestellnummer findest Du z.B. in der Bestellbestätigungs-Mail oder auf der Rechnung.
            </FormControl.HelperText>
          </FormControl>
          <FormControl>
            <FormControl.Label justifyContent={"center"}>E-Mail Adresse</FormControl.Label>
            <Input onChangeText={(text) => {
              setCustomerEmail(text)
            }} isDisabled={isLoading} borderColor={"brand.900"} _hover={{ borderColor: "brand.900" }} _focus={{ borderColor: "brand.900", backgroundColor: "yellow.100" }} textAlign={"center"} placeholder='miau@yellowcatstore.com' />
            <FormControl.HelperText leftIcon={<InfoOutlineIcon size="xs" />}>
              Nutze die E-Mail Adresse, die Du für Deine Bestellung angegeben hast.
            </FormControl.HelperText>
          </FormControl>
          <Button isLoading={isLoading} isDisabled={customerEmail.length < 3 || orderName.length < 3} onPress={() => {
            loginWithOrder()
          }} mt="2" backgroundColor="brand.900" _text={{
            color: "black",
            fontSize: "md",
            fontWeight: "bold"
          }}>
            ANMELDEN
          </Button>
          <Button onPress={() => {
            Linking.openURL("https://yellowcatstore.com")
          }} mt="1" backgroundColor="gray.100" _text={{
            color: "black",
            fontSize: "md",
            fontWeight: "bold"
          }}>
            ZUM STORE
          </Button>
        </VStack>
      </Box>
    </Center>
  );
};


export default LoginView;